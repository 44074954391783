/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 15%; /* 0 width - change this with JavaScript */
  min-width: 200px;
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #17181c; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  border-right: 2px solid #25262f;
}

.text {
  color: #505267;
  display: block;
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
}

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nameBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pseudo {
  color: #e7e7e7;
  font-size: 30px;
  margin-top: 20px;
}

.title {
  color: #7970ff;
  font-size: 16px;
  margin-top: 5px;
}

.menu {
  padding: 50px;
}
.separatorEmpty {
  height: 10px;
}
.separator {
  height: 2px;
  background: #25262f;
  width: 70%;
  margin: 20px 15%;
}

.menu-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #505267;
}

.menu-link:hover .menuIcon {
  -webkit-animation: ring 0.2s 0.1s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 0.2s 0.1s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 0.3s 0.1s ease-in-out;
  transform-origin: 50% 4px;
}

.menu-link-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #505267;
  transition: 0.3s;
}

.menu-link-content span {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  align-items: center;
}

.menu-link span {
  margin-left: 17px;
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  align-items: center;
}

.currentGameBtn:hover {
  border-color: #7970ff;
}

.currentGameBtn:hover .currentGameText {
  color: #7970ff;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  33% {
    -webkit-transform: rotateZ(10deg);
  }
  66% {
    -webkit-transform: rotateZ(-10deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  33% {
    -moz-transform: rotate(10deg);
  }
  66% {
    -moz-transform: rotate(-10deg);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  33% {
    transform: rotate(10deg);
  }
  66% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}
