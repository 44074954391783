.card {
  width: 165px;
  height: 264px;
  align-self: center;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.story-image-backdrop {
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 13px;
  object-fit: cover;
  filter: drop-shadow(0px 12.0538px 12.0538px rgba(0, 0, 0, 0.25));
}

.story-image-title {
  position: absolute;
  bottom: 1%;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.2s ease-in-out;
  border-radius: 13px;
  object-fit: contain;
}
