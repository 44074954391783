.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
}

@-moz-keyframes fadeIn {
    0% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
}

@-webkit-keyframes fadeIn {
    0% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
}

@-o-keyframes fadeIn {
  0% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-ms-keyframes fadeIn {
    0% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
}
