.supperposition {
  position: relative;
}

.supperposition {
  transition: 0.3s;
}
.fond {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  border-radius: 7;
  object-fit: "cover";
}
.bustePerso {
  position: absolute;
  left: 0;
  z-index: 14;
  aspect-ratio: 1;
  object-fit: contain;
}
.perso {
  position: absolute;
  left: 0;
  z-index: 16;
  aspect-ratio: 1;
  object-fit: contain;
}
.buste {
  position: absolute;
  left: 0;
  z-index: 15;
  aspect-ratio: 1;
  object-fit: contain;
}
.masque {
  position: absolute;
  left: 0;
  z-index: 18;
  aspect-ratio: 1;
  object-fit: contain;
}
.chapeau {
  position: absolute;
  left: 0;
  z-index: 17;
  aspect-ratio: 1;
  object-fit: contain;
}
