/* .prevIcon {
  display: none !important;
}

.nextIcon {
  display: none !important;
} */

.nextIcon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.8s linear;
}

.slider:hover .nextIcon {
  visibility: visible;
  opacity: 1;
}
.prevIcon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.8s linear;
}

.slider:hover .prevIcon {
  visibility: visible;
  opacity: 1;
}

.slider:hover .prevButton {
  transition: background 0.3s ease-in-out;
  background: hsla(0, 0%, 8%, 0.5);
}

.slider:hover .nextButton {
  transition: background 0.3s ease-in-out;
  background: hsla(0, 0%, 8%, 0.5);
}

.prevButton:hover,
.nextButton:hover {
  transition: background 0.3s ease-in-out;
  background: hsla(0, 0%, 8%, 0.7) !important;
}
/* .slider:hover .prevIcon {
  transition: display 1s ease-in-out;
  display: block !important;
}
.slider:hover .nextIcon {
  transition: display 1s ease-in-out;
  display: block !important;
} */

.prevButton:hover .prevIcon {
  transition: font-size 0.2s ease-in-out;
  font-size: 2.5rem;
}

.nextButton:hover .nextIcon {
  transition: font-size 0.2s ease-in-out;
  font-size: 2.5rem;
}

.carouselCard:first-child {
  margin-left: 4vw;
}

.carouselCard:last-child {
  margin-right: 4vw;
}
