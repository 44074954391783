.spinIn {
  /* -webkit-animation: spin 0.2s linear;
  -moz-animation: spin 0.2s linear;
  animation: spin 0.2s linear; */
  transition: transform 0.25s;
}

.spinOut {
  transform: rotate(180deg);
  transition: transform 0.25s;
}