
@keyframes fadeout {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform: scaleY(0);
    }
  }
  
  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .text-reveal {
    transition: all 0.2s ease-in-out;
    transform-origin: left top;
    transform: scaleY(1);
  }
  
  
  .toggleOut {
    animation: fadeout 500ms;
    opacity: 0;
  }
  
  .toggleIn {
    animation: fadein 500ms;
    opacity: 1;
  }