.library {
  background-color: #17181c;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: "relative";
}

.bg-library {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Stay on top */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow: "hidden";
}

.cards-container {
  height: 100%; /* 100% Full-height */
  /* min-height: 350px; */
  padding-top: 30px;
  padding-bottom: 30px;
}

.selected-story-info-container {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 30px; */
}

.selected-story-info {
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selected-story-title {
  color: #e7e7e7;
  font-size: 4vh;
  font-family: "Eczar-Regular";
}

.selected-story-game-info {
  display: flex;
  width: 70%;
  margin-top: 1vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selected-player-number {
  color: #e7e7e7;
  font-size: 1.5vh;
  font-family: "Gilroy-Bold";
  margin-left: 7px;
}

.info-with-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.selected-average-time {
  color: #7970ff;
  font-size: 1.5vh;
  font-family: "Gilroy-Bold";
  margin-left: 7px;
}

.selected-episode-number {
  color: #cd47f4;
  font-size: 1.5vh;
  font-family: "Gilroy-Bold";
  margin-left: 7px;
}

.selected-story-themes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.story-theme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.89286px 11.5714px;
  border-radius: 100px;
  border: solid 2px;
  margin-right: 5px;
}

.selected-story-description {
  color: #7b7e9e;
  font-size: 1.8vh;
  margin-top: 2vh;
  margin-bottom: 4vh;
  font-family: "Gilroy-Bold";
  text-align: center;
}

.MuiModal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content::-webkit-scrollbar {
  display: none;
}


