.linear-gradient-btn {
  width: 50%;
  border-radius: 13px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #cd47f4 0%, #7970ff 100%);
}

.disabled-gradient-btn {
  width: 50%;
  border-radius: 13px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #505267;
}

.disabled-gradient-btn:hover {
  /* transform: scale(1.03); */
  cursor: not-allowed;
}

.linear-gradient-btn:hover {
  /* transform: scale(1.03); */
  cursor: pointer;
}
